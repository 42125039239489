import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
// add axios defaults
import './axios'
import * as Sentry from '@sentry/browser'
import { FeatureFlagsProvider } from '@fs/zion-flags'

import { Router } from '@fs/zion-router'
import { appPath, sentryDSN, mergeSentryConfig } from '@fs/zion-config'
import { Root } from '@fs/identity.js'
import { NoticeLoading } from '@fs/zion-icon'
import { setAppLocales, allLocales } from '@fs/zion-locale'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './locales'
import { Urls } from './constants'

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      dsn: sentryDSN,
      // help avoid FS quota issues
      sampleRate: 0.1,
    })
  )
}

const header = {
  hidden: window.location.pathname.includes(Urls.LITE),
}

setAppLocales(allLocales)
// This is where you pass data from the server to the client using the SERVER_DATA global.
// Here we pass the mounted app path from the server to the client.
const base = appPath ? new URL(appPath).pathname : ''

const FrontierRoot = () => (
  <React.StrictMode>
    <Suspense fallback={<NoticeLoading />}>
      <FeatureFlagsProvider mockFlags={mockFlags}>
        <Router basename={base}>
          <Root analytics header={header}>
            <App />
          </Root>
        </Router>
      </FeatureFlagsProvider>
    </Suspense>
  </React.StrictMode>
)

ReactDOM.render(<FrontierRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
