import React from 'react'
import { Separator, Skeleton } from '@fs/zion-ui'
import LinkSkeleton from './LinkSkeleton'

const path = window.location.pathname || '/'
const useLinkSkeleton = path.includes('/link') || path.includes('/lite')

export default function AppSkeleton() {
  return useLinkSkeleton ? <LinkSkeleton /> : <LoginSkeleton />
}

const LoginSkeleton = () => {
  return (
    <>
      {/* Sign in Heading */}
      <Skeleton.H4 />
      <Separator size="sm" />

      {/* Social Providers */}
      <Skeleton.Button maxWidth="100%" />
      <Separator size="xs" />
      <Skeleton.Button maxWidth="100%" />
      <Separator size="xs" />
      <Skeleton.Button maxWidth="100%" />
      <Separator size="xs" />
      <Skeleton.Button maxWidth="100%" />

      <Separator size="md" />

      {/* Username  */}
      <Skeleton.Paragraph maxWidth="20%" size="xs" />
      <Skeleton.Button maxWidth="100%" />
      <Skeleton.Paragraph maxWidth="30%" size="xs" />

      <Separator size="sm" />

      {/* Password */}
      <Skeleton.Paragraph maxWidth="20%" size="xs" />
      <Skeleton.Button maxWidth="100%" />
      <Skeleton.Paragraph maxWidth="30%" size="xs" />

      <Separator size="sm" />

      {/* Submit Button */}
      <Skeleton.Button maxWidth="100%" />
      <Separator size="xs" />
      {/* Create Account Button */}
      <Skeleton.Button maxWidth="100%" />
    </>
  )
}
