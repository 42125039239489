import React from 'react'
import { Separator, Skeleton } from '@fs/zion-ui'

export default function LinkSkeleton() {
  return (
    <>
      {/* Sign in Heading */}
      <Skeleton.H1 />
      {/* <Separator size="sm" /> */}

      {/* <Skeleton.HeaderBlock size="md" /> */}
      <Separator size="sm" />

      {/* Username  */}
      <Skeleton.Paragraph maxWidth="20%" size="xs" />
      <Skeleton.Button maxWidth="100%" />
      <Skeleton.Paragraph maxWidth="30%" size="xs" />

      <Separator size="sm" />

      {/* Password */}
      <Skeleton.Paragraph maxWidth="20%" size="xs" />
      <Skeleton.Button maxWidth="100%" />
      <Skeleton.Paragraph maxWidth="30%" size="xs" />

      <Separator size="sm" />

      {/* Submit Button */}
      <Skeleton.Button maxWidth="100%" />
    </>
  )
}
