import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { NotFound, Switch, Route } from '@fs/zion-router'
import { PageHead } from '@fs/zion-head'
import ErrorBoundary from '@fs/zion-error-boundary'
import { PageWrapper } from '@fs/identity.js'
import { i18n } from '@fs/zion-locale'
import AppSkeleton from './components/AppSkeleton'
import { Urls } from './constants'

// Dynamically load components to reduce bundle size
// https://reactjs.org/docs/react-api.html#reactlazy

const LoginPage = React.lazy(() => import('./components/LoginPage'))
const LinkExistingAccountPage = React.lazy(() => import('./components/LinkExistingAccountPage'))
const LinkPage = React.lazy(() => import('./components/LinkPage'))
const LiteLoginPage = React.lazy(() => import('./components/LiteLoginPage'))
const RussianTermsPage = React.lazy(() => import('./components/RussianTermsPage'))

function App() {
  const { t } = useTranslation()
  const titleText = i18n.getResource(i18n.language, 'translation', 'sign-in.title')
    ? t('sign-in.title', 'Sign-in to your account')
    : t('common-ui:sign-in.action', 'Sign In')
  return (
    <ErrorBoundary>
      <PageHead title={titleText} description="" omitSiteName />
      <PageWrapper maxWidth="nano">
        <Suspense fallback={<AppSkeleton />}>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path={Urls.LINK} component={LinkPage} />
            <Route exact path={Urls.LINK_EXISTING_ACCOUNT} component={LinkExistingAccountPage} />
            <Route exact path={Urls.LITE} component={LiteLoginPage} />
            <Route exact path={Urls.RUSSIAN_TERMS} component={RussianTermsPage} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </PageWrapper>
    </ErrorBoundary>
  )
}
export default App
